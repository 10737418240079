.testimonials-container {
    margin-top: 40px;
  }
  
  .testimonials-title {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .testimonials-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .testimonial-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
  
  .testimonial-card:hover {
    box-shadow: 0 6px 8px #2E8B57;
    transform: translateY(-5px);
  }
  
  .testimonial-name {
    text-align: center;
    margin-bottom: 10px;
  }
  
  .testimonial-title {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .testimonial-comment {
    text-align: justify;
  }
  