.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




.fade-in-text {
  animation: fadeIn 2s;
  color: #0024c6;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
   text-decoration: underline;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.paraone {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: inherit;
  text-align: justify;
}

.Navbar {
  height: 5%;
}






body {
  margin: 0;

  text-align: center;

}
.container {
  position: relative;
  width: 320px;
  margin: 100px auto 0 auto;
  perspective: 1000px;
}

.carousel {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d; 
  animation: rotate360 60s infinite forwards linear;
}
.carousel__face { 
  position: absolute;
  width: 300px;
  height: 187px;
  top: 20px;
  left: 10px;
  right: 10px;
  background-size: cover;
  display: flex;
}

span {
  margin: auto;
  font-size: 2rem;
}


.carousel__face:nth-child(1) {
  background-image: url("https://i.ibb.co/dWKK1sq/DIAGEO.jpg");
  transform: rotateY(  0deg) translateZ(430px); }
.carousel__face:nth-child(2) { 
  background-image: url("https://i.ibb.co/Dtv5KMM/ABD.png");
    transform: rotateY( 40deg) translateZ(430px); }
.carousel__face:nth-child(3) {
  background-image: url("https://i.ibb.co/R4s3cbf/US.png");
  transform: rotateY( 80deg) translateZ(430px); }
.carousel__face:nth-child(4) {
  background-image: url("https://i.ibb.co/GpsjMff/Radico.png");
  transform: rotateY(120deg) translateZ(430px); }
  .carousel__face:nth-child(5) {
    background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRWbXYxCzKoJy1CY9i1w-A3WNa4qKgiKt3KA&usqp=CAU");
    transform: rotateY(160deg) translateZ(430px); }
    .carousel__face:nth-child(6) {
      background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTBXASQBA5XMRIwPbUaXAcC0x0Iw34lzr44g&usqp=CAU");
      transform: rotateY(200deg) translateZ(430px); }
      .carousel__face:nth-child(7) {
        background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7NE6jZ5n--lwgqrp5YW3VMrpM38VLD1tngQ&usqp=CAU");
        transform: rotateY(240deg) translateZ(430px); }
        .carousel__face:nth-child(8) {
          background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTMcU8tdck7-q4-kM9rJUNsScfKMmoNpR4Jw&usqp=CAU");
          transform: rotateY(280deg) translateZ(430px); }
          .carousel__face:nth-child(9) {
            background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNYLan1n59sXGfJkUhQ6efiZLXu58QuNaouA&usqp=CAU");
            transform: rotateY(320deg) translateZ(430px); }



@keyframes rotate360 {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(-360deg);
  }
}



.photon {
  border-radius: 10%;
  width: 75%;
  height: 75%;
  
}

.galler {
  border-radius: 10%;
}

.clien {
  width: 160px;
  height: 90px;
  border-radius: 10%;
}

